<script>
import { useSelectedPlan } from '@/stores/plans/selectedPlan.js'
import { retrieveCoverage } from '@/composables/coverage.comp.js'

export default {
	mounted() {
		this.createUserIdentity().then((res) => {
			// Setup log rocket identity
			if (import.meta.env.VITE_LOGROCKET_ID) {
				try {
					window.LogRocket.identify(res.userName, {
						userName: res.userName,
						memberId: res.memberId,
						groupId: res.groupId,
						planType: res.planType,
						lob: res.lob
					})
				} catch (err) {
					console.error(err)
				}
			}
		})
	},
	methods: {
		async createUserIdentity() {
			const planStore = useSelectedPlan()
			const coverageStore = await retrieveCoverage().then((res) => res)

			return {
				memberId: planStore.memberId,
				groupId: coverageStore.getGroupId(planStore.memberId),
				userName: planStore.username,
				planType: planStore.lobDisplayName,
				lob: planStore.tier1
			}
		}
	}
}
</script>
